<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                    <template v-if="account">
                    &gt; <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ account.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card class="pa-0">
                        <v-app-bar color="teal" dark flat dense>
                            <v-app-bar-title>Create new brand</v-app-bar-title>
                        </v-app-bar>
                        <v-card-text class="px-5">
                        <v-form @submit.prevent="onSubmitForm" @keyup.enter.native="onSubmitForm">
                            <p>
                                Brands are used to identify your product, service, or organization.
                            </p>
                            <p>
                                The brand name is publicly visible.
                                <!-- The comment is private and only shown to authorized users. -->
                            </p>

                            <!-- <v-select outlined dense :items="newBrandTypeChoices" v-model="newBrandType" color="green" label="Type" required hint="The kind of data to which this brand will be applied">
                            </v-select> -->

                            <v-text-field
                                ref="brandNameInput"
                                v-model="newBrandName"
                                label="Name"
                                :rules="newBrandNameRules"
                                validate-on-blur
                                color="green"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>

                            <!-- <v-textarea
                                ref="brandCommentInput"
                                v-model="newBrandComment"
                                label="Comment"
                                hint="This is just for you to remember how you are using the brand"
                                :rules="newBrandCommentRules"
                                validate-on-blur
                                color="green"
                                type="text"
                                outlined
                                dense
                            >
                            </v-textarea> -->

                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="onSubmitForm" :disabled="!isFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        typeList: [],

        error: null,
        website: null,
        // create form
        createBrandForm: null,
        submitFormTimestamp: null,
        // newFormAlias: null,
        // newFormAliasRules: [
        //     (v) => !!v || 'Display alias is required',
        //     (v) => !v || isValidFormAlias(compact(v)) || 'Form alias is required',
        // ],
        newBrandName: null,
        newBrandNameRules: [
            (v) => !!v || 'Name is required',
        ],
        // newBrandType: null,
        // newBrandTypeChoices: [], // will be loaded from server
        newBrandComment: null,
        newBrandCommentRules: [
            // (v) => !!v || 'comment is required',
        ],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            focus: (state) => state.focus,
            account: (state) => state.account,
        }),
        isFormComplete() {
            return typeof this.newBrandName === 'string' && this.newBrandName.trim().length > 0;
        },
    },
    watch: {
        focus() {
            setTimeout(() => { this.$nextTick(() => this.$refs.brandNameInput.focus()); }, 1);
        },
    },
    methods: {
        // async loadWebsite(id) {
        //     try {
        //         this.$store.commit('loading', { loadWebsite: true });
        //         const response = await this.$client.account(this.$route.params.accountId).currentAccount.website.get(id);
        //         console.log(`loadWebsite: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.website = response;
        //             // if the form is for a website, suggest an appropriate form label (user can still edit this)
        //             if (this.newBrandName === null || this.newBrandName.length === 0) {
        //                 this.newBrandName = `Website content for ${this.website.label}`;
        //             }
        //         }
        //     } catch (err) {
        //         console.error('failed to load website', err);
        //         this.website = null;
        //     } finally {
        //         this.$store.commit('loading', { loadWebsite: false });
        //     }
        // },
        onSubmitForm() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            if (!this.isFormComplete) {
                return;
            }
            this.createBrand();
        },
        cancel() {
            // this.$emit('cancel');
            this.$router.go(-1);
        },
        async createBrand() {
            try {
                this.error = false;
                this.$store.commit('loading', { createBrand: true });
                console.log('createBrand');
                const request = {
                    name: this.newBrandName,
                    // type: this.newBrandType,
                    // comment: this.newBrandComment,
                };
                const response = await this.$client.account(this.$route.params.accountId).brand.create(request);
                console.log('createBrand response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated && id) {
                    // const form = { id, ...request };
                    // this.list.push(form); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                    this.$router.replace({ name: 'account-edit-brand', params: { accountId: this.$route.params.accountId, brandId: id } });
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create brand' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create brand' });
                    this.error = 'Request failed';
                }
                // this.$emit('created', { id });
            } catch (err) {
                console.error('failed to create brand', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create brand' });
            } finally {
                this.$store.commit('loading', { createBrand: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        // this.loadTypeList();
        setTimeout(() => { this.$nextTick(() => this.$refs.brandNameInput.focus()); }, 1);
    },
};
</script>
